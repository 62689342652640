@keyframes spin {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

#spinner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100px;
  animation: spin 1.5s linear infinite;
}