#nav {
  color: white;
  grid-area: nav;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  padding: 0px 20px;
  font-size: 2.5rem;
  background-color: var(--green);
  position: sticky;
  top: 0px;
  z-index: 5;
}

#logo {
  display: flex;
  align-items: center;
  font-family: 'MuseoModerno', sans-serif;
  align-self: center;
}

#links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;
  margin-right: 25px;
}

#nav a {
  box-sizing: border-box;
  font-size: 1.5rem;
  color: white;
  padding: 10px;
  height: 100%;
  border-radius: 2px;
  margin: 0px;
  text-decoration: none;
}

a:hover, a.active {
  transition: .6s;
  background-color: var(--greenD);
}



