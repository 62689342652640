#submit {
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#submit h1 {
  color: white;
  margin-bottom: 0px;
}

#submit form {
  display: grid;
  grid: 
    'txt textarea' 1fr 
    'btn btn' 50px 
    /1fr 1fr;
  gap: 10px;
  padding: 25px;
  margin: 10px 25px 25px 25px;
  min-width: 320px;
  width: 80%;
  border-radius: 5px;
  background-color: var(--green);
  box-shadow: 0 0 5px 2px var(--greenD);
}

#submit input::placeholder, #submit textarea::placeholder {
  color: var(--greenD);
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
}

#submit input:focus, #submit textarea:focus {
  outline: none;
  transition: .6s;
  transform: scale(1.01);
  border: 2px solid var(--greenD);
}

#submit input:valid, #submit textarea:valid {
  border: 2px solid var(--beige);
}

#submit input:invalid, #submit textarea:invalid {
  border: 2px solid red;
}

#submit input:empty, #submit textarea:empty {
  border: none;
}

#submit input, #submit textarea {
  color: black;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  appearance: none;
  display: block;
  width: 100%;
  padding: 0;
  margin: 10px 0px;
  border: none;
  background-color: white;
  box-shadow: 0px 0px 4px 1px var(--greenD);
}

#submit textarea {
  resize: none;
}

.textarea {
  grid-area: textarea;
}

.text {
  grid-area: txt;
}

.textarea, .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

#submit label {
  font-size: 1.2rem;
  width: 80%;
}

#submit button {
  grid-area: btn;
  justify-self: center;
  min-width: 300px;
  width: 40%;
  max-width: 400px;
  font-size: 1.2rem;
  color: white;
  appearance: none;
  background-color: var(--greenD);
  border: none;
  margin-bottom: 5px;
  border-radius: 5px;
  box-shadow: 2px 2px 2px var(--green);
}

#submit button:hover {
  transition: .6s;
  opacity: .7;
}

#submit button:active {
  box-shadow: none;
}

@media (max-width: 900px) {
  #submit form {
    display: flex;
    flex-direction: column;
  }
}