#details {
  grid-area: main;
  padding: 5px;
  margin: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
}

#details img {
  float: right;
  max-width: 50%;
  padding-left: 25px;
}

#details #text {
  font-size: 1.3rem;
  font-weight: 700;
  color: var(--greenVD);
  background-color: var(--green);
  border-radius: 5px;
  padding: 10px 50px;
  box-shadow: 0px 0px 8px var(--greenD);
}

#details h1, #details h2, #details h3 {
  color: white;
}

#details a {
  box-sizing: border-box;
  font-size: 1.1rem;
  color: white;
  /* height: 100%; */
  padding: 1px 3px;
  margin: 0px;
}