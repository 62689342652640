@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=MuseoModerno:wght@400;700&display=swap');

:root {
  --beige: #C8B88A;
  --green: #86B049;
  --greenD: #476930;
  --greenVD: #3D550C;
}

/* body { background-color: var(--blue); } */

body {
  margin: 0px;
  background-color: var(--beige);
  color: white;
  font-family: 'Montserrat', sans-serif;
}

#app {
  display: grid;
  grid: 
    'nav nav' 10fr
    'main main' 90fr
    /75fr 25fr;
    width: 100vw;
    height: 100vh;
    padding: 0px;
    margin: 0px;
    overflow-y: auto;
}