#search {
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}

#search-box {
  font-size: 1.3rem;
  width: 60%;
  margin-top: 40px;
}

#search form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}

#search input {
  font-size: 1.3rem;
}

#sort-by {
  margin: 5px;
}

#search-btn {
  font-size: 1.3rem;
  appearance: none;
  border: none; 
  background-color: var(--beige);
  padding: 0px 5px;
}

#arrows-box {
  margin-bottom: 10px;
}

.arrow {
  font-size: 2.5rem;
  font-weight: 900;
  background-color: var(--green);
  padding: 0px 20px;
  margin: 20px;
  border-radius: 5px;
}

.arrow:hover {
  transition: .6s;
  background-color: var(--greenD);
}