.recipe {
  position: relative;
  background-color: var(--green);
  border: 1px solid white;
  padding: 0px;
  width: 300px;
  height: 300px;
  border-radius: 5px;
  box-shadow: 0 0 8px var(--greenD);
}

#image-box {
  width: 100%;
  height: 50%;
  min-height: 200px;
  background-size: cover;
  background-position: center;
}

.recipe:hover {
  transition: .6s;
  transform: scale(1.03) rotate(1.2deg);
}

.recipe h2 {
  font-size: 1.2rem;
  padding: 0px 10px;
}

#likeBtn {
  position: absolute;
  font-size: 1.3rem;
  right: 3px;
  top: 3px;
  margin: 0px;
}