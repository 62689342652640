#home {
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#home h1 {
  margin-bottom: 10px;
}

#show-another {
  font-size: 1.5rem;
  color: white;
  appearance: none;
  background-color: var(--green);
  border: none;
  margin-bottom: 5px;
  border-radius: 5px;
  box-shadow: 2px 2px 2px var(--greenD);
}

#show-another:hover {
  transition: .6sec;
  opacity: .8;
}

#show-another:active {
  box-shadow: none;
}

#home #likeBtn {
  position: absolute;
  font-size: 1.8rem;
  right: 40px;
  top: 5px;
  margin: 0px;
}

#home img {
  max-height: 200vh;
  max-width: 95%;
  border-radius: 5px;
  box-shadow: 0px 0px 8px var(--greenD);
}

#image-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}
